<template>
  <!-- <div class="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/tracker">Tracker</router-link>
  </div> -->
  <router-view/>
</template>

<style lang="scss">
// .nav {
//   text-align: center;

//   a {
//     color: $gold;
//   }
// }
</style>
