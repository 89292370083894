<template>
  <!-- <div class="top-nav">
    <a href="https://app.lormaster.com" target="_blank" class="link-app btn btn-primary"><i class="fas fa-bolt p-1"></i> Web App</a>
  </div> -->
  <div class="tracker container-fluid">
    <!-- <img src="@/assets/logo.png" alt=""> -->
    <div class="main-content row d-flex">
      <div class="left-column col-lg-9 position-relative container">
        <div class="nav-container position-absolute h-100">
          <nav class="nav nav-pills text-start flex-lg-column sticky-top">
            <div class="bg" data-rellax-speed="0.1"></div>
            <div class="nav-title">Features:</div>
            <a class="nav-link" v-for="(section, index) in features" :key="index" :href="'#section' + index">
              <i :class="featuresIcon[index]" class="me-1"></i>
              {{ section }}
            </a>
          </nav>
        </div>
        <div class="display-container">
          <display-screenshot
            @loaded="handleLoaded"
            v-for="(feature, index) in features"
            :key="index"
            class="display py-5 py-md-0"
            :id="'section' + index"
            :src="featuresImg[index]"
            :description="featuresText[index] ? featuresText[index] : null"
          >
            {{ features[index] }}
          </display-screenshot>
        </div>
      </div>
      <div class="col-lg-3 text-center position-relative right-column">
        <div class="sticky-lg-top download-container">
          <picture>
            <source :srcset="logoImageURLWebP" type="image/webp" />
            <source :srcset="logoImageURL" type="image/png" />
            <img class="logo" :src="logoImageURL" alt="" />
          </picture>
          <h1 class="title display-3">LOR MASTER TRACKER</h1>
          <p class="sub-title container-fluid mt-4 mb-3">
            A free & open source <br />
            Legends of Runeterra deck tracker
          </p>
          <a class="download btn btn-primary mt-3" :href="downloadLink">
            <i class="fab fa-windows"></i> Download
            <span
              class="badge bg-dark version"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="downloadCount + ' Downloads'"
              >{{ version }}</span
            >
          </a>
          <p class="pt-2"><small>For Windows 10 or 11</small></p>
          <a
            class="download btn btn-primary mt-3 ms-2"
            href="https://app.lormaster.com"
            target="_blank"
            rel="noopener"
            aria-label="Web App"
          >
            <i class="fas fa-globe"></i> Web App <i class="ps-2 pe-0 fas fa-arrow-right"></i
          ></a>
          <div class="socials mt-5">
            <a href="https://discord.gg/aes45bMjAz" target="_blank" rel="noopener" aria-label="Discord"><i class="fab fa-discord"></i> </a>
            <a href="https://github.com/shaobaili3/LoR_Master" target="_blank" rel="noopener" aria-label="Github"
              ><i class="fab fa-github"></i
            ></a>
            <a href="https://twitter.com/storm_lor" target="_blank" rel="noopener" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
          </div>
          <div class="mt-2">
            <p class="mb-1">
              <small>Built by Masters for (future) Masters</small>
            </p>
            <p>
              <small>
                <a
                  class="text-link"
                  href="https://twitter.com/storm_lor"
                  target="_blank"
                  rel="noopener"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Peak Rank 1 NA"
                >
                  Storm</a
                >
                &
                <a
                  class="text-link"
                  href="https://twitter.com/FlyingFishLoR"
                  target="_blank"
                  rel="noopener"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="SEA Seasonal Champ"
                >
                  FlyingFish</a
                ></small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--     
    <div class="vh-100 row">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa voluptatibus veniam totam quos beatae modi eius adipisci hic. Saepe dolorem quam reiciendis provident molestiae tenetur perspiciatis voluptate consectetur impedit minus.
    </div> -->
  </div>
</template>

<script>
// const githubTagURL = 'https://api.github.com/repos/shaobaili3/LoR_Master/tags'
const githubURL = "https://api.github.com/repos/shaobaili3/LoR_Master/releases/latest"
import axios from "axios"
import DisplayScreenshot from "../components/DisplayScreenshot.vue"

import tooltip from "bootstrap/js/src/tooltip"

// console.log(tooltip)

import Rellax from "rellax"

export default {
  components: { DisplayScreenshot },
  props: {},
  data() {
    return {
      logoImageURLWebP: require("@/assets/images/logo.webp").default,
      logoImageURL: require("@/assets/images/logo.png").default,

      modal: null,
      downloadLink: null,
      version: "",
      downloadCount: 0,

      features: [
        "Tracker Overlay",
        "Search & Analyze History",
        "Interactive Leaderboard",
        "Deck Details",
        "Discover Meta",
        "Auto Update",
        "Free & Open Source",
      ],
      featuresIcon: [
        "far fa-window-alt",
        "far fa-search",
        "far fa-trophy",
        "far fa-analytics",
        "far fa-trees",
        "far fa-turkey",
        "fab fa-github",
      ],
      featuresImg: [
        [require("@/assets/images/tracker.webp").default, require("@/assets/images/tracker.png").default],
        [require("@/assets/images/history.webp").default, require("@/assets/images/history.png").default],
        [require("@/assets/images/leaderboard.webp").default, require("@/assets/images/leaderboard.png").default],
        [require("@/assets/images/detail.webp").default, require("@/assets/images/detail.png").default],
        [require("@/assets/images/meta.webp").default, require("@/assets/images/meta.png").default],
        [require("@/assets/images/update.webp").default, require("@/assets/images/update.png").default],
        [require("@/assets/images/repo.webp").default, require("@/assets/images/repo.png").default],
      ],
      featuresText: [
        "Easily see opponent history, double check played cards, and remind yourself what's left in deck",
        "🔍 Quick overview of history & winrates or apply some filter for deeper analysis",
        "Click & Search, see what Top Players are playing 👀",
        "📈 View detailed analytics & matchup stats",
        "🌲 Filter & discover top meta decks & hidden gems",
        "Standalone app and easily kept updated",
        "Free & Open Source is simply Awesome! We are in active development, come Fork & PR.",
      ],

      rellaxes: null,
      // featuresComponent: ['display-screenshot', 'display-screenshot', 'display-screenshot','display-screenshot']
    }
  },
  mounted() {
    this.rellaxes = new Rellax(".rellax", {
      speed: -2,
      center: false,
    })

    // console.log(tooltip)
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
      return new tooltip(tooltipTriggerEl)
    })

    document.querySelector(".nav-link").classList.add("active")

    axios
      .get(githubURL)
      .then((response) => {
        this.version = response.data.tag_name
        var info = response.data.assets.filter((item) => item.name.includes(".exe") && !item.name.includes(".blockmap"))[0]
        this.downloadLink = info.browser_download_url
        this.downloadCount = info.download_count
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    handleLoaded() {
      console.log("Refresh Rellax")
      this.rellaxes.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.top-nav {
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;

  .link-app {
    // text-decoration: none;
    // border-radius: 0.2rem;
    padding: 0.4rem 0.6rem;
    // background: $gold;
    // color: $background;
  }
}

.download {
  position: relative;
  .link-app {
    height: 100%;
    position: absolute;
    white-space: nowrap;
    left: 100%;
    top: 0;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.main-content {
  .left-column {
    .nav-container {
      .nav {
        display: none;
      }
    }
  }

  .right-column {
    .logo {
      width: 5em;
      padding-bottom: 1em;
    }

    .socials {
      a {
        padding: 0.3em;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .main-content {
    .left-column {
      .nav-container {
        left: 0;
        width: 100%;
        // background: #1c1c1f;
        // z-index: 10;

        .nav {
          display: inline-block;
          white-space: nowrap;
          width: 100%;
          overflow: scroll;
          @include scrollbars(0px, transparent, transparent);

          .bg {
            position: absolute;
            // top: 1rem;
            width: 100%;
            height: 100%;
            // background: $gold;
            background: #1c1c1f80;
            // background: #28282880;
            backdrop-filter: blur(8px);
            border-radius: 0.5rem;
          }

          padding: 0.5rem 1rem 1rem 0rem;

          .nav-title {
            display: inline-block;
            padding: 1rem 1rem;
            // padding-bottom: 1rem;
            cursor: default;
            z-index: 1;
          }

          .nav-link {
            z-index: 1;
            display: inline-block;
            transition: transform 0.2s ease;
            &:hover,
            &:focus {
              color: $light-gold;
            }
            &.active {
              color: $dark;
            }
          }
        }
      }

      .display-container {
        .display {
          height: 100vh;
        }
      }
    }
    .right-column {
      .download-container {
      }
    }
  }

  .tracker {
    text-align: center;
  }

  .socials a:hover,
  .socials a:focus,
  a.text-link:hover,
  a.text-link:focus {
    color: $light-gold;
  }

  .btn {
    text-decoration: none;
    color: black;
    padding: 0.8em;
    // background: $gold;

    font-weight: 500;

    .badge {
      font-weight: 400;
    }

    &.download {
    }

    .badge {
      background-color: $background;
    }

    i {
      padding-right: 0.5em;
    }
  }
}

// End of main styles

@include media-breakpoint-up(lg) {
  .main-content {
    .left-column {
      .nav-container {
        left: auto;
        right: calc(var(--bs-gutter-x) * 0.5);
        width: auto;

        .nav {
          display: flex;
          width: auto;
          transform: translateY(calc(50vh - 50%));
          // top: 50%;

          overflow: visible;

          .nav-title {
          }

          .nav-link {
            &.active {
              transform: translateX(-0.8em) translateY(-0.2em);
            }
          }
        }
      }
    }
    .right-column {
      .download-container {
        transform: translateY(calc(50vh - 50%));
        // padding-top: 50vh;
      }
    }
  }
}
</style>
