import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Tracker from '../views/Tracker.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Tracker,
    meta: {
      title: 'Legends of Runeterra Tracker'
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'About'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' | LoR Master';
  next();
});

export default router
