import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap'
import '@/scss/global.scss'

// import "./css/fontawesome/all.min.css"

const app = createApp(App).use(router)

import { ObserveVisibility } from 'vue-observe-visibility'

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
})

app.mount('#app')

import "@/css/fontawesome/all.min.css";
